import {createSlice, configureStore, PayloadAction} from '@reduxjs/toolkit';
import {initialState} from './states';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import env from '@/constants/env';

const authStoreSlice = createSlice({
  name: 'authStore',
  initialState,
  reducers: {
    setToken: (state, token: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = token.payload;
    },
    fetchDataStart: (state, action) => {
      state.appLoading = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetState: (state) => {
      return initialState; // Set the state back to its initial values
    },
    setID: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
    setCredit: (state, credit) => {
      state.loan = credit.payload.loan;
      state.year = credit.payload.year;
    },
    setLeadData: (state, lead) => {
      state.lead = lead.payload;
    },
    setDocsData: (state, docs) => {
      state.docs = docs.payload;
    },
    setMetaData: (state, meta) => {
      state.metaData = meta.payload;
    },
    setSelectedProduct: (state, meta) => {
      state.selectedProduct = meta.payload;
    },
    setFinalStep: (state, step) => {
      state.finalStep = step.payload;
      state = initialState;
    },
    getToken: () => {
      handleCall();
    },
    getUser: (state, id: PayloadAction<string>) => {
      handleGetUser(id.payload);
    },
  },
});

export const {setToken, setID, getToken, setCredit, setDocsData,
  setLeadData, fetchDataStart, setMetaData, getUser, setFinalStep,
  setSelectedProduct, resetState} = authStoreSlice.actions;

const store = configureStore({
  reducer: authStoreSlice.reducer,
});

const handleCall = async () => {
  const searchParams = await new URLSearchParams(window.location.search);
  const params = await Object.fromEntries(Array.from(searchParams.entries()));
  // console.log(params.lead_id, 'test');
  handleGetMeta();
  if (params)
    store.dispatch(setLeadData({...store.getState().lead, ...params}));
  if (params.lead_id) {
    await store.dispatch(fetchDataStart(true));
    await store.dispatch(setID(params.lead_id));
    await handleGetUser(params.lead_id);
    return;
  }
};

const handleGetUser = (id: string) => {
  const toastId = toast.loading('Loading your data...');
  authHttp.get(`api/salesforce/lead/${id}/`).then((res) => {
    const leadData = replaceNullsWithEmptyStrings({
      ...res.data,
      Duration__c: res.data.Duration_in_Years__c,
    });
    store.dispatch(setLeadData({...leadData}));
    toast.success(`Welcome ${res.data.FirstName}`, {
      id: toastId,
    });
    handleGetDocs(id);
  }).catch(() => {
    toast.dismiss(toastId);
  });
};

const handleGetDocs = async (id: string) => {
  await authHttp.get(`api/salesforce/lead/${id}/attachment/`)
    .then(async (res) => {
      const listDocs = res.data.records.map(
        (el) => {
          return {
            ...el,
            Name: el.Title,
            file: el.Title,
            Id: el.ContentDocumentId,
          };
        },
      );
    store.dispatch(setDocsData(listDocs));
    localStorage.setItem('docs', JSON.stringify(listDocs));
    store.dispatch(fetchDataStart(false));
  }).catch(() => {
    store.dispatch(fetchDataStart(false));
  });
};

const handleGetMeta = async () => {
  await authHttp.get(`api/salesforce/metadata/?country=${env.Country}`)
    .then(async (res) => {
    store.dispatch(setMetaData(res.data.records));
    store.dispatch(fetchDataStart(false));
  });
};

const replaceNullsWithEmptyStrings = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      replaceNullsWithEmptyStrings(obj[key]);
    }
  }
  return obj;
};


export default store;
