/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {authHttp} from '@/apis/axiosHttp';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Collapse from 'react-bootstrap/Collapse';
// import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import store, {setLeadData} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import {countryObjects} from '@/components/utils/countrieslist';
import env from '@/constants/env';

type Props = {
  handleDone: (number: number) => void;
  aanvraag?: boolean;
};

type CustomToggleProps = {
  eventKey: number;
  activeKey: number;
  children: React.ReactNode;
  decoratedOnClick: React.MouseEventHandler<HTMLDivElement>;
  completed: number;
  handleDeleting: React.MouseEventHandler<SVGSVGElement>;
};

type partnerType = {
  FirstName: string;
  LastName: string;
  Email: string;
  Birthdate__c: string;
  Gender__c: string;
  Name?: string;
  Country_of_Birth__c: string;
  Initials__c: string;
};

type ModalProps = {
  onHide: () => void;
  show: boolean;
  onDelete: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const MyVerticallyCenteredModal = (props: ModalProps) => {
  const {translate} = useLocales();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {translate('delete')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{translate('delete_partner')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={props.onHide}>
          {translate('close')}
        </Button>
        <Button onClick={props.onDelete}>{translate('delete')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

const CustomToggle = ({
  children,
  eventKey,
  activeKey,
  decoratedOnClick,
  completed,
  handleDeleting,
}: CustomToggleProps) => {
  const isCurrentEventKey = activeKey === eventKey;
  const isReady = completed - Number(eventKey);

  return (
    <div
      className={`cursor-pointer ${
        isReady >= 0 ? 'accordion_header_normal' : 'accordion_header_disabled'
      }`}
      onClick={isReady >= 0 ? decoratedOnClick : null}
    >
      <div className="checked_row">
        <div>{children}</div>
      </div>
      <div className="mr-2 text-right">
                          <svg
                            width="18"
                            height="21"
                            viewBox="0 0 25 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
          onClick={handleDeleting}
                          >
                            <path
                              d="M5.27344 0.756958C5.46875 0.327271
                        5.89844 0.053833 6.36719 0.053833H11.0938C11.5625
                         0.053833 11.9922 0.327271 12.1875 0.756958L12.5
                          1.30383H16.25C16.9141 1.30383 17.5 1.88977 17.5
                           2.55383C17.5 3.25696 16.9141 3.80383 16.25
                            3.80383H1.25C0.546875 3.80383 0 3.25696 0
                             2.55383C0 1.88977 0.546875 1.30383 1.25
                              1.30383H5L5.27344 0.756958ZM15.3906
                               18.296C15.3516 19.3116 14.5312 20.0538
                                13.5156 20.0538H3.94531C2.92969 20.0538
                                 2.10938 19.3116 2.07031 18.296L1.21094
                                  5.05383H16.25L15.3906 18.296Z"
                              fill="#002172"
                            />
                          </svg>
        {isCurrentEventKey ? (
          <svg
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.703125 9.83167L9.65625 1.25354C9.9375 1.01917
            10.2188 0.87854 10.5 0.87854C10.7812 0.87854 11.0156
              0.97229 11.25 1.15979L20.2031 9.73792C20.6719 10.2067
              20.6719 10.9098 20.25 11.3317C19.8281 11.8004 19.125
                11.8004 18.6562 11.3785L10.5 3.59729L2.25 11.4723C1.82812
                11.8942 1.07812 11.8942 0.65625 11.4254C0.234375 10.9567
                  0.234375 10.2535 0.703125 9.83167Z"
              fill="#002172"
            />
          </svg>
        ) : (
          <svg
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.25 2.84375L11.2969 11.4219C11.0156 11.6562
             10.7344 11.75 10.5 11.75C10.2188 11.75 9.9375 11.6562
              9.70312 11.4688L0.703125 2.84375C0.234375 2.42188
               0.234375 1.67188 0.65625 1.25C1.07812 0.78125 1.82812
                0.78125 2.25 1.20312L10.5 9.07812L18.7031 1.20312C19.125
                 0.78125 19.875 0.78125 20.2969 1.25C20.7188 1.67188
                  20.7188 2.42188 20.25 2.84375Z"
              fill="#002172"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

const BusinessForm = ({handleDone, aanvraag}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(true);
  const [validatedSub, setValidatedSub] = React.useState<boolean>(true);
  const [activeKey, setActiveKey] = React.useState<number>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [viewInfo, setViewInfo] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [deleteData, setDeleteData] = React.useState<number>(null);
  const leadInfo = state.lead;
  const [partnerList, setPartnerList] = React.useState<partnerType[]>([]);
  const {translate} = useLocales();
  React.useEffect(() => {
    const name = leadInfo.Partner_ApplicationData__c;
    if (!validated)
      if (name) {
        const list = JSON.parse(leadInfo.Partner_ApplicationData__c);
        // console.log(list);
        setPartnerList([...list, ...partnerList.slice(1)]);
        setValidated(true);
      }
  }, [partnerList, leadInfo, validated]);

  const dataLayerHandler = () => {
    const existingLoanInfo = JSON.parse(localStorage.loanInfo || '{}');
    const updatedDataLayer = {
      ...existingLoanInfo.dataLayer,
      stap_aanvraag: 'Vennoten',
    };
    const tagManagerArgs = {
        gtmId: env.GtmId,
        dataLayer: updatedDataLayer,
    };
    const updatedInfo = {
      ...existingLoanInfo,
      dataLayer: updatedDataLayer,
  };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(updatedInfo));
  };

  const handleSubmit = () => {
    const toastId = toast.loading(translate('loading'));
    const list = partnerList.filter(
      (el) => el.FirstName.trim().length > 1 && el.LastName.trim().length > 1,
    );
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      Partner_ApplicationData__c: JSON.stringify(list),
      Lead_Scoring__c: 'Hot',
      ApplicationData__c: JSON.stringify(store.getState().lead),
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: {
    //       Partner_ApplicationData__c: JSON.stringify(list),
    //       Lead_Scoring__c: 'Hot',
    //       ApplicationData__c: JSON.stringify(store.getState().lead),
    //     },
    //   })
      .then(() => {
        handleDone(!aanvraag ? 6 : 4);
        store.dispatch(
          setLeadData({
            ...store.getState().lead,
            Partner_ApplicationData__c: JSON.stringify(list),
            // ApplicationData__c: JSON.stringify(store.getState().lead),
          }),
        );
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        dataLayerHandler();
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  const handleSubDone = () => {
    if (!validatedSub && partnerList.length !== 0) {
      setIsSubmitting(true);
      return;
    } else {
      setIsSubmitting(false);
    }
    if (!validatedSub && partnerList.length !== 0) return;
    const newList = [
      ...partnerList,
      {
        FirstName: '',
        LastName: '',
        Email: '',
        Birthdate__c: '',
        Gender__c: '',
        Name: `Zakenpartner ${partnerList.length + 1}`,
        Initials__c: '',
        Country_of_Birth__c: env.Country,
      },
    ];
    setPartnerList([...newList]);
    setActiveKey(partnerList.length);
    setValidated(true);
  };

  const selectAccord = (event: number) => {
    if (event === activeKey) setActiveKey(null);
    else setActiveKey(event);
  };

  React.useEffect(() => {
    const ind = partnerList.length - 1;
    if (
      partnerList.length > 0 &&
      partnerList[ind].FirstName !== '' &&
      partnerList[ind].LastName !== '' &&
      partnerList[ind].Email !== '' &&
      partnerList[ind].Initials__c &&
      partnerList[ind].Birthdate__c &&
      partnerList[ind].Gender__c !== ''
      ) {
      partnerList.forEach((ele, index) => {
        if (partnerList[index].Name.trim().length < 4)
          partnerList[index].Name = `Zakenpartner
            ${index + 1}`;
        setPartnerList([...partnerList]);
      });
      const list = partnerList.filter(
        (el) => el.FirstName.trim().length > 1 && el.LastName.trim().length > 1,
      );
      store.dispatch(
        setLeadData({
          ...store.getState().lead,
          Partner_ApplicationData__c: JSON.stringify(list),
        }),
      );
      setValidatedSub(true);
      // eslint-disable-next-line brace-style
    }
    // setValidatedSub(partnerList.length === 0);
    else setValidatedSub(false);
  }, [partnerList]);

  const handleDateChange = (value) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
    const minDate = new Date(
      currentDate.getFullYear() - 100,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const maxDate = new Date(
      currentDate.getFullYear() - 16,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    if (inputDate < minDate || inputDate > maxDate) {
      setValidatedSub(false);
      toast.error(translate('age_incorrect_msg'));
    } else {
      setValidatedSub(true);
    }
  };

  const handleIndexDeleting = (indexToRemove: number) => {
    setDeleteData(indexToRemove);
    setModalShow(true);
  };

  const deletePartner = () => {
    const updatedList = partnerList.filter((_, index) => index !== deleteData);
    setPartnerList((prev) => {
      if (prev !== updatedList) {
        return updatedList;
      }
      return updatedList;
    });

    const updatedLeadData = {
        ...store.getState().lead,
        Partner_ApplicationData__c: JSON.stringify(updatedList),
    };

    store.dispatch(setLeadData(updatedLeadData));

    setDeleteData(null);
    setModalShow(false);
};

  return (
    <Card.Body className="mobile_form partner_form">
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={() => deletePartner()}
      />
      <div className="h-100 pb-6">
      <div className="mobile_header">{translate('associates')}</div>
      <p className="mt-1 mobile_details">
        {translate('if_applicable_business_parter_details')}
      </p>
      <div>
        <div className="sub-accordion">
          {partnerList.map((element, index) => (
            <Card
              key={index}
              className={`mb-3 ${
                activeKey === index ? 'accordion-item' : 'sub-collapsed'
              } `}
            >
              <CustomToggle
                activeKey={activeKey}
                eventKey={index}
                completed={partnerList.length}
                decoratedOnClick={() => selectAccord(index)}
                handleDeleting={() => handleIndexDeleting(index)}
              >
                <div className="accordion_title">
                  {element.Name}
                </div>
              </CustomToggle>
              <Collapse in={activeKey === index}>
                <div>
                  <Row className="mb-3 align-items-center">
                    <Form.Group controlId="validationCustom01">
                      <ButtonGroup aria-label="Basic example" className="mt-3">
                        <Button
                          className={(!partnerList[index].Gender__c &&
                            isSubmitting) && 'invalid-border'}
                variant={
                            element?.Gender__c === 'Female' ?
                              'outline-success' :
                              'outline-primary'
                          }
                          onClick={() => {
                            partnerList[index].Gender__c = 'Female';
                            setPartnerList([...partnerList]);
                          }}
                        >
                          {translate('mrs')}
                        </Button>
                        <Button
                          className={(!partnerList[index].Gender__c &&
                            isSubmitting) && 'invalid-border'}
                variant={
                            element?.Gender__c === 'Male' ?
                              'outline-success' :
                              'outline-primary'
                          }
                          onClick={() => {
                            partnerList[index].Gender__c = 'Male';
                            setPartnerList([...partnerList]);
                          }}
                        >
                          {translate('mr')}
                        </Button>
                        {/* <Button
                          variant={
                            element?.Gender__c === 'Neutral' ?
                              'outline-success' :
                              'outline-primary'
                          }
                          onClick={() => {
                            partnerList[index].Gender__c = 'Neutral';
                            setPartnerList([...partnerList]);
                          }}
                        >
                          {translate('other')}
                        </Button> */}
                      </ButtonGroup>
                    </Form.Group>
                  </Row>
                  <InputField
                    isRequired
                    isValidated={setValidated}
                    handleChange={(e: string) => {
                      partnerList[index].FirstName = e.trim();
                      partnerList[index].Name = `${
                        e.trim() + ' ' + partnerList[index].LastName.trim()
                      }`;
                      if (partnerList[index].Name.trim().length < 4)
                        partnerList[index].Name = `Zakenpartner
                       ${index + 1}`;
                      setPartnerList([...partnerList]);
                    }}
                    label={translate('f_name')}
                    type="text"
                    defaultValue={element.FirstName}
          isSubmitting={isSubmitting}
                  />
                  <InputField
                    isRequired
                    isValidated={setValidated}
                    handleChange={(e: string) => {
                      partnerList[index].Initials__c = e.trim();
                      setPartnerList([...partnerList]);
                    }}
                    label={translate('initials_business')}
                    type="initial"
          isSubmitting={isSubmitting}
          defaultValue={element.Initials__c }
                  />
                  <InputField
                    isRequired
                    isValidated={setValidated}
                    handleChange={(e: string) => {
                      partnerList[index].LastName = e.trim();
                      if (partnerList[index].Name.trim().length < 4)
                        partnerList[index].Name = `Zakenpartner
                       ${index + 1}`;
                      partnerList[index].Name = `${
                        partnerList[index].FirstName.trim() + ' ' + e.trim()
                      }`;
                      setPartnerList([...partnerList]);
                    }}
                    label={translate('l_name')}
                    type="text"
                    defaultValue={element.LastName}
          isSubmitting={isSubmitting}
                  />
                  <InputField
                    isRequired
                    isValidated={setValidated}
                    handleChange={(e: string) => {
                      partnerList[index].Email = e;
                      setPartnerList([...partnerList]);
                    }}
                    label={translate('email')}
                    type="email"
                    defaultValue={element.Email}
          isSubmitting={isSubmitting}
                  />
                  {/* <Form.Group controlId="validationCustom05">
                    <Form.Select
                      required
                      className="full_select"
                      aria-label="Burgelijke staat"
                    >
                      <option>Burgelijke staat</option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group controlId="validationCustom06">
                    <InputField
                      isRequired
                      isValidated={setValidated}
                      handleChange={(
                        e: string,
                      ) => {
                        partnerList[index].Birthdate__c = e;
                        setPartnerList([...partnerList]);
                      }}
                      label={translate('dob')}
                      type="date"
                      labelClasses="level-input"
                      defaultValue={element.Birthdate__c}
                      handleFocus={(e) => handleDateChange(e)}
                      isSubmitting={isSubmitting}
                    />
                  <>
                <span className="mb-3 select-text">{translate('cob')}</span>
                  <Form.Select
                    className="full_select"
                    value={element.Country_of_Birth__c}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      partnerList[index].Country_of_Birth__c = e.target.value;
                      setPartnerList([...partnerList]);
                    }}
                  >
                    {countryObjects.map((product, index) => (
                      <option value={product.code} key={index}>
                        {product.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
                    {viewInfo && (
                      <div className="more-details mt-3">
                        <div className="row m-0 justify-content-between">
                          <div className="col-11 text-justify">
                            {translate('why_we_ask_bkr')}
                          <a rel="noreferrer" target='_blank' href="https://www.bkr.nl/veelgestelde-vragen/registratie-bij-stichting-bkr/wat-betekent-een-registratie-voor-mij/">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-box-arrow-up-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.636 3.5a.5.5 0 0
                          0-.5-.5H1.5A1.5 1.5
                            0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0
                            1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0
                            1-.5.5h-10a.5.5 0 0
                              1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0
                              .5-.5z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M16 .5a.5.5 0 0
                          0-.5-.5h-5a.5.5 0 0 0
                            0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15
                            1.707V5.5a.5.5 0 0 0
                            1 0v-5z"
                              />
                            </svg></a>{' '}
                          </div>
                          <div
                            onClick={() => setViewInfo(false)}
                            className="col-1"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.46875 5.46875C5.75 5.1875 6.21875
                            5.1875 6.5
                            5.46875L7.96875 6.96875L9.46875 5.46875C9.75 5.1875
                              10.2188 5.1875 10.5 5.46875C10.8125
                               5.78125 10.8125
                              6.25 10.5 6.53125L9.03125 8L10.5
                               9.46875C10.8125
                                9.78125 10.8125 10.25 10.5 10.5312C10.2188
                                 10.8438
                                9.75 10.8438 9.46875 10.5312L7.96875
                                 9.0625L6.5
                                  10.5312C6.21875 10.8438 5.75 10.8438 5.46875
                                  10.5312C5.15625 10.25 5.15625 9.78125
                                   5.46875
                                    9.46875L6.9375 8L5.46875
                                     6.53125C5.15625 6.25
                                    5.15625 5.78125 5.46875 5.46875ZM16 8C16
                                    12.4375
                                      12.4062 16 8 16C3.5625 16 0 12.4375 0
                                      8C0 3.59375
                                      3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8
                                        1.5C4.40625 1.5 1.5 4.4375 1.5
                                        8C1.5 11.5938
                                        4.40625 14.5 8 14.5C11.5625 14.5
                                        14.5 11.5938
                                          14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
                                fill="#002172"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Collapse>
            </Card>
          ))}
        </div>
        <div className='my-4'>
          <button
            className={`btn ${!validatedSub && partnerList.length !== 0 ?
              'btn-sub-disabled' : 'btn-primary'}
            w-100 mobile_button`}
            onClick={() => handleSubDone()}
          >
            <svg width="30" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14
 7.99805H8V13.998H6V7.99805H0V5.99805H6V-0.00195312H8V5.99805H14V7.99805Z"
                  fill="white"
                />
</svg>


            {translate('add_parnter')}
          </button>
        </div>
          {partnerList.length === 0 &&
            <Form.Group controlId="validationCustom05" className='mb-5 ml-4
         warning-checkbox'
            >
          <Form.Check
            type="checkbox"
            id="default-checkbox"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setChecked(e.target.checked);
              setValidated(e.target.checked);
            }}
            label={translate('no_partner')}
          />
        </Form.Group>}
      </div>
      <div className="pb-4 mb-2">
        <div>
          <button
            disabled={!validatedSub && (partnerList.length !== 0 || !checked)}
            className="btn btn-success
          px-5 mobile_button"
            onClick={() => handleSubmit()}
          >
            {translate('next_step')}
          </button>
        </div>
      </div>
     </div>
      <div className="previous-button pl-1 pb-4" onClick={() => handleDone(3)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
    </Card.Body>
  );
};

export default BusinessForm;
