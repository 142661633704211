/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import '@/assets/styles/app.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import routes from '@/router/routes';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useMediaQuery} from 'react-responsive';
import Logo from '@/assets/images/qredit-logo.png';
import UserBlueIcon from '@/assets/images/MijnQredits Vector - blue.svg';
import UserOrangeIcon from '@/assets/images/MijnQredits Vector - orange.svg';
import PhoneIcon from '@/assets/images/phone_footer.svg';
import CheckIcon from '@/assets/images/footer_check.svg';
import FooterImage from '@/assets/images/qredits-logo-with-footer.svg';
import '@/context/i18n';
import {stateType} from '@/types/state';
import {useSelector} from 'react-redux';
// import FooterImage from '@/assets/images/Qredits_footer_rgb.png';
import useLocales from '@/hooks/useLocales';
import {
  findLangByEnv,
  findMyQreditsLink,
  linkByEnv, findCallNumByEnv,
} from '@/components/utils/helper';

const App: React.FC = () => {
  const state = useSelector((state: stateType) => state);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 700px)'});
  const {translate, onChangeLang} = useLocales();

  useEffect(() => {
    onChangeLang(findLangByEnv());
  }, []);

  React.useEffect(() => {
    if (!state.finalStep)
      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        event.returnValue = !state.finalStep ?
          `Are you sure that you want
    to close the application ?` :
          '';
      });

    return () => {
      window.removeEventListener('beforeunload', (event) => {
        event.preventDefault();
        event.returnValue = !state.finalStep ?
          `Are you sure that you want
    to close the application ?` :
          '';
      });
    };
  }, [state.finalStep]);

  return (
    <div
      style={{
        overflowY: 'auto',
        height: '100vh',
      }}
    >
      <Navbar bg="white" expand="lg" sticky="top">
        <Container>
          {!isTabletOrMobile && <Navbar.Toggle aria-controls="basic-navbar-nav">
            <Nav.Link
              href={linkByEnv()}
              className="text-primary"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="32"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.36384 3.75296L9.68747 3.40963L9.36347
                 3.06664L8.03148 1.65665L7.66802 1.27189L7.30455
                  1.65665L1.63653 7.65664L1.31218 8L1.63653 8.34336L7.30455
                   14.3434L7.66802 14.7281L8.03148 14.3434L9.36347
                    12.9334L9.68747 12.5904L9.36384 12.247L5.36053 8L9.36384
                     3.75296Z"
                  fill="#002172"
                  stroke="#002172"
                />
              </svg>
            </Nav.Link>
          </Navbar.Toggle>}
          <Navbar.Brand href={linkByEnv()} target="_blank" rel="noreferrer">
            {!isTabletOrMobile ? (
              <img
                className="desktop-brand"
                src={Logo}
                alt=""
                height={125}
                width={125}
              />
            ) : (
              <img
                className="mobile-brand"
                src={Logo}
                alt=""
                height={66}
                width={66}
              />
            )}
          </Navbar.Brand>
          {!isTabletOrMobile && (
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={
                !isTabletOrMobile ? 'text-primary' : 'text-primary mob-drop'
              }
            >
              <Nav.Link
                href={linkByEnv()}
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="32"
                  height="19"
                  viewBox="0 0 12 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.36384 3.75296L9.68747 3.40963L9.36347
                 3.06664L8.03148 1.65665L7.66802 1.27189L7.30455
                  1.65665L1.63653 7.65664L1.31218 8L1.63653 8.34336L7.30455
                   14.3434L7.66802 14.7281L8.03148 14.3434L9.36347
                    12.9334L9.68747 12.5904L9.36384 12.247L5.36053 8L9.36384
                     3.75296Z"
                    fill="#002172"
                    stroke="#002172"
                  />
                </svg>
                {translate('go_back_home')}
              </Nav.Link>
            </Navbar.Collapse>
          )}
          <Nav.Link
            href={findMyQreditsLink()}
            className={!isTabletOrMobile ? 'text-warning' : 'text-primary mr-2'}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="desktop-brand-user mb-1"
              src={!isTabletOrMobile ? UserOrangeIcon : UserBlueIcon}
              alt=""
              height={20}
              width={20}
            />
            {translate('my_qredits')}
          </Nav.Link>
        </Container>
      </Navbar>
      <div className='content' style={{backgroundColor: !isTabletOrMobile ? '#F6F5FA' : ''}}>
        <Router>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Router>
      </div>
      {!isTabletOrMobile ? <div>
      <div
        className="custom-footer"
      >
        <img className="footer-image" src={FooterImage} alt="" />
        <div className="d-flex align-items-center footer-content">
          <h5>{translate('business_start_with')}</h5>
          <div style={{width: 120, height: 120}}>
          </div>
        </div>
      </div>
      <div className='bottom-blocker'></div>
      </div> :
        <div style={{height: '36vh'}}>
          <div className="mobile-footer pb-5 h-100">
            <div className="mb-5">
            <div className='mobile_footer_checktext font-weight-bold mb-3'>
            <img
                className="mobile-brand"
                src={CheckIcon}
                alt=""
                height={12}
                width={14}
            />
              {translate('Usp_1')}
            </div>
            <div className='mobile_footer_checktext font-weight-bold mb-3'>
            <img
                className="mobile-brand"
                src={CheckIcon}
                alt=""
                height={12}
                width={14}
            />
              {translate('Usp_2')}
            </div>
            <div className='mobile_footer_checktext font-weight-bold mb-3'>
            <img
                className="mobile-brand"
                src={CheckIcon}
                alt=""
                height={12}
                width={14}
            />
              {translate('Usp_3')}
            </div>
            </div>
            <div className='mobile_footer_tel font-weight-bold mb-1 mt-1'>
              {translate('help_text')}
            </div>
            <div className="mobile_footer_tel mb-3 pb-3">
              <img
                className="mobile-brand"
                src={PhoneIcon}
                alt=""
                height={33}
                width={33}
              />
                <a
                  href={`tel:${findCallNumByEnv()}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{textDecoration: 'none'}}
                  className="mobile_footer_text text-primary"
                >
                  {findCallNumByEnv()}
                </a>
            </div>
          </div>
        </div>}
    </div>
  );
};

export default App;
