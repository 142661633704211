/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {authHttp} from '@/apis/axiosHttp';
// import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import store, {setID, setLeadData} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import {phoneNumByEnv} from '@/components/utils/helper';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';

type Props = {
  handleDone: (number: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ContactForm = ({handleDone}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const leadInfo = state.lead;
  const [checked, setChecked] = React.useState<boolean>(false);
  const [selectBtn, setSelectBtn] = React.useState<string>(null);
  const [firstName, setFirstName] = React.useState<string>('');
  const [secondName, setSecondName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>(phoneNumByEnv());
  const [initials, setInitials] = React.useState<string>('');
  const {translate} = useLocales();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };

  const handleSubmit = () => {
    if (!validated || loading) {
      setIsSubmitting(true);
      return;
    } else {
      setIsSubmitting(false);
    }
    setLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Duration__c: Number(store.getState().year) * 12,
      Status: 'Incomplete',
      Initials__c: initials,
      Google_CID__c: getCookie('_ga'),
      AccountId__c: leadInfo.AccountId__c,
      Company: `${firstName} ${secondName}`,
      // RT_developer_name__c: 'Credit Application',
      Country: env.Country === 'NL' ? 'NLD' : env.Country || 'NLD',
      COC_Number__c: leadInfo.COC_Number__c,
      Proposition__c: store.getState().selectedProduct.Label,
    };
    if (params.lead_id || store.getState().id) updateLead(payload);
    else createLead(payload);
  };

  const createLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    authHttp.post('api/salesforce/lead/', {
      ...payload,
      ApplicationData__c: JSON.stringify(payload),
    })
      .then((res) => {
        store.dispatch(setID(res.data.id));
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        handleDone(2);
        dataLayerHandler(res);
        setLoading(false);
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
        setLoading(false);
      });
  };

  const dataLayerHandler = (res) => {
    const existingLoanInfo = JSON.parse(localStorage.getItem('loanInfo')) || {};
    // console.log('existingLoanInfo1', existingLoanInfo);

    const updatedDataLayer = {
      ...existingLoanInfo,
      mailadres: email,
      stap_aanvraag: 'Contactgegevens',
      lead_id: setID(res.data.id).payload,
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: updatedDataLayer,
    };
    // console.log('existingLoanInfo2', existingLoanInfo);
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem(
      'loanInfo',
      JSON.stringify({
        dataLayer: updatedDataLayer,
      }),
    );
  };

  const updateLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    handleDone(2);
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  React.useEffect(() => {
      setFirstName(leadInfo.FirstName);
      setSecondName(leadInfo.LastName);
      setEmail(leadInfo.Email);
      setSelectBtn(leadInfo.Gender__c);
      setPhone(leadInfo.Phone);
      setInitials(leadInfo.Initials__c);
      setChecked(leadInfo.Agree_to_Privacy_Policy__c);
  }, []);

  React.useEffect(() => {
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Initials__c: initials,
      Duration__c: store.getState().year,
      Application_Submitted__c: 'New',
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (
      firstName &&
      secondName &&
      selectBtn &&
      email &&
      phone &&
      checked &&
      initials
    )
      setValidated(true);
   else setValidated(false);
  }, [firstName, secondName, selectBtn, email, phone, checked, initials]);

  return (
    <Card.Body className="mobile_form contact-details-mobile">
      <div className="mobile_header mb-3">{translate('contact_details')}</div>
      <Form>
        <Row className="mb-3 align-items-center">
          <Form.Group controlId="validationCustom01">
            <ButtonGroup aria-label="Basic example">
              <Button
                className={(!selectBtn && isSubmitting) && 'invalid-border'}
                variant={
                  selectBtn === 'Female' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Female')}
              >
                {translate('mrs')}
              </Button>
              <Button
                className={(!selectBtn && isSubmitting) && 'invalid-border'}
                variant={
                  selectBtn === 'Male' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Male')}
              >
                {translate('mr')}
              </Button>
              {/* <Button
                variant={
                  selectBtn === 'Neutral' ?
                    'outline-success' :
                    'outline-primary'
                }
                onClick={() => setSelectBtn('Neutral')}
              >
                {translate('other')}
              </Button> */}
            </ButtonGroup>
          </Form.Group>
        </Row>
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setFirstName(e)}
          label={translate('f_name')}
          type="text"
          defaultValue={firstName}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setInitials(e)}
          label={translate('initials_business')}
          type="initial"
          defaultValue={initials}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setSecondName(e)}
          label={translate('l_name')}
          type="text"
          defaultValue={secondName}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setEmail(e)}
          label={translate('email')}
          type="email"
          defaultValue={email}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setPhone(e)}
          label={translate('phone')}
          type="tel"
          defaultValue={phone}
          isSubmitting={isSubmitting}
        />
        <Form.Group controlId="validationCustom05">
          <Form.Check
            required
            className={(!validated && isSubmitting) && 'invalid-checkbox'}
            type="checkbox"
            id="default-checkbox"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setChecked(e.target.checked)
            }
            label={
              <>{translate('agree_TOC')}
                <a target='_blank' href='https://qredits.nl/service/algemene-voorwaarden.html?gad=1&gclid=CjwKCAjw3oqoBhAjEiwA_UaLtmHYAVW4h6SEFIA-BPQVRPD7AgFNg6KWLN-KItimts85QZUXPU0Z-hoCBrAQAvD_BwE&_gl=1*1e0p0im*_up*MQ..*_ga*MjY5NDQxNzU0LjE3MjkwNzY1NDE.*_ga_D37PVRKF58*MTcyOTA3NjU0MS4xLjEuMTcyOTA3NjU0MS4wLjAuMA..'
                              rel="noreferrer" style={{textDecoration: 'none'}}
                >
                                      &#128279;</a>
                  </>
            }
          />
        </Form.Group>
      </Form>
      <div className="mt-4  pb-4">
        <div>
          <button
            type="submit"
            className={`btn ${!validated || loading ?
              'btn-disabled' : 'btn-success'}
            px-5 mobile_button`}
            onClick={handleSubmit}
          >
            {translate('next_step')}
          </button>
        </div>
      </div>
      <div className="previous-button pb-4" onClick={() => handleDone(0)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
    </Card.Body>
  );
};

export default ContactForm;
