/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import InputField from '@/components/common/InputField';
import store, {setLeadData} from '@/store';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';
import {countryObjects} from '@/components/utils/countrieslist';

type Props = {
  handleDone: (number: number) => void;
  aanvraag?: boolean;
};

type AddressType = {
  house_number_from: number;
  house_number_to: number;
  neighborhood_code: number;
  letter_combination: string;
  range_indication: number;
  street_name: string;
  street_name_nen: string;
  city: string;
  municipality: string;
  municipality_code: number;
  cebuco_code: number;
  province: string;
  province_code: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const PersonForm = ({handleDone, aanvraag}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [viewInfo, setViewInfo] = React.useState<boolean>(false);
  const [citizen, setCitizen] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const leadInfo = state.lead;
  const [street, setStreet] = React.useState<string>('');
  const [postCode, setPostCode] = React.useState<string>('');
  const [house, setHouse] = React.useState<string>('');
  const [toevoeg, setToevoeg] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<AddressType>(null);
  const [isDobValid, setIsDobValid] = React.useState<boolean>(true);
  const [dob, setDob] = React.useState<string>('');
  const [isCorrect, setIsCorrect] = React.useState<boolean>(false);
  const [city, setCity] = React.useState<string>('');
  const [country, setCountry] = React.useState(env.Country);
  const [district, setDistrict] = React.useState<string>('');
  const today = new Date().toISOString().split('T')[0];
  const {translate} = useLocales();
  const handleSubmit = () => {
    if (!validated) {
      setIsDobValid(dob ? isDobValid : false);
      setIsSubmitting(true);
      return;
    } else {
      setIsSubmitting(false);
    }
    const toastId = toast.loading(translate('loading'));
    const payload = {
      Birthdate__c: dob,
      // StreetName: street,
      Postal_Code_Visiting__c: convertPostCode(postCode),
      Street_Number_visiting__c: house,
      Street_Number_Addition_visiting__c: toevoeg,
      Street_visiting__c: street || address?.street_name,
      City_visiting__c: address?.city,
      Country_visiting__c: citizen ? country : 'Netherlands',
      Married__c: checked,
      Lead_Scoring__c: 'Medium',
    };
    const IslandPayload = {
      Birthdate__c: dob,
      Street_Number_visiting__c: house,
      Street_Number_Addition_visiting__c: toevoeg,
      Street_visiting__c: street,
      Neighbourhood_District__c: district,
      Country_of_Birth__c: country,
      Married__c: checked,
      Lead_Scoring__c: 'Medium',
      Country_visiting__c: country,
    };
      const finalPayload = env.Country === 'NL' ? payload : IslandPayload;
      authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
        ...finalPayload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: env.Country === 'NL' ? payload: IslandPayload,
    //   })
      .then(() => {
        handleDone(
          leadInfo.Company_type === 'eenmanszaak' && checked ?
            5 :
            !aanvraag && checked ?
            4 :
            5,
        );
        store.dispatch(setLeadData({
          ...store.getState().lead,
          ...finalPayload,
        }));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        dataLayerHandler();
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  React.useEffect(() => {
      setDob(leadInfo.Birthdate__c);
      setPostCode(leadInfo.Postal_Code_Visiting__c);
      setHouse(leadInfo.Street_Number_visiting__c);
      setToevoeg(leadInfo.Street_Number_Addition_visiting__c);
      setStreet(leadInfo.Street_visiting__c);
      setChecked(leadInfo.Married__c || false);
  }, []);

  const updateAddress = (address) => {
    if (address?.street_name)
      authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
        Street_visiting__c: address?.street_name,
        City_visiting__c: address?.city,
      });
      // apiHttp
      // .create('api/salesforce/', {
      //   method: 'PATCH',
      //   path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
      //   headers: [
      //     {
      //       key: 'Content-Type',
      //       value: 'application/json',
      //     },
      //   ],
      //   body: {
      //     Street_visiting__c: address?.street_name,
      //     City_visiting__c: address?.city,
      //   },
      // });
  };

  const handleAddress = () => {
    if (
      postCode &&
      house &&
      ['BEL', 'NLD', 'NL'].some((item) =>
      country.includes(item))
    ) {
      const toastId = toast.loading(translate('loading'));
      apiHttp
        .create('api/companyinfo/address/', {
          postcode: convertPostCode(postCode),
          house_number: house,
          house_number_addition: toevoeg,
          // page: page,
        })
        .then(({data}) => {
          setAddress(data);
          updateAddress(data);
          toast.dismiss();
          setIsCorrect(false);
        })
        .catch(() => {
          toast.error(translate('invalid_address_mgs'), {
            id: toastId,
          });
          setAddress(null);
          updateAddress(null);
          setIsCorrect(false);
        });
    } else setAddress(null);
  };

  const convertPostCode = (code) => {
    const oldRegex = /^\s*\d{4}(\s*-?\s*[A-Z]{2})\s*$/i;
    const newValue = code?.replace(oldRegex, (match) => {
      const trimmedValue = match.trim();
      const matches = trimmedValue.match(oldRegex);
      const digits = matches[0].match(/\d/g).join('');
      const letters = matches[0]
        .match(/[a-zA-Z]/g)
        .join('')
        .toUpperCase();
      return `${digits}${letters}`;
    });
    return newValue || '';
  };

  React.useEffect(() => {
    const payload = {
      Birthdate__c: dob,
      Postal_Code_Visiting__c: convertPostCode(postCode),
      Street_Number_visiting__c: house,
      Street_Number_Addition_visiting__c: toevoeg,
      Street_visiting__c: street,
      Married__c: checked,
      Lead_Scoring__c: 'Medium',
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (house && dob) {
      if (isCorrect || citizen) {
        setValidated(true);
      } else {
        setValidated(false);
      }
    } else setValidated(false);
  }, [postCode, house, dob, toevoeg, street, checked, isCorrect, citizen]);

  React.useEffect(() => {
    if (!['BEL', 'NLD'].some((item) => country.includes(item)))
      setAddress(null);
  }, [country]);

  const handleDateChange = (value) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
    const minDate = new Date(
      currentDate.getFullYear() - 100,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const maxDate = new Date(
      currentDate.getFullYear() - 16,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    if (inputDate < minDate || inputDate > maxDate) {
      setIsDobValid(false);
      toast.error(translate('age_incorrect_msg'));
    } else {
      setIsDobValid(true);
    }
  };

  const dataLayerHandler = () => {
    const existingLoanInfo = JSON.parse(localStorage.loanInfo || '{}');
    const updatedDataLayer = {
      ...existingLoanInfo.dataLayer,
      stap_aanvraag: 'Persoonlijke gegevens',
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      updatedDataLayer,
    };
    const updatedInfo = {
      ...existingLoanInfo,
      dataLayer: updatedDataLayer,
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(updatedInfo));
  };

  return (
    <Card.Body className="mobile_form pt-3">
      <div className="mobile_header">{translate('personal_information')}</div>
      <p className="mt-1 mobile_details">
        {translate('fill_details_business_advisor_mgs')}
      </p>
      {env.Country === 'NL' ? (
        <div>
          <Form.Group controlId="validationCustom01">
            <FloatingLabel
              controlId="floatingInput"
              label={translate('dob')}
              className="mb-3"
            >
              <input
                required
                type="date"
                className={`${((!isDobValid && isSubmitting) ||
                  (!isDobValid && dob)) ? 'is-invalid' : ''}
form-control`}
                pattern="\d{4}-\d{2}-\d{2}"
                max={today}
                value={dob}
                placeholder={translate('dob')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDob(e.target.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateChange(e.target.value)
                }
              />
            </FloatingLabel>
          </Form.Group>
          {citizen && (
            <InputField
              handleChange={(e: string) => setStreet(e)}
              label={translate('street_name')}
              type="text"
              defaultValue={street}
            />
          )}
          <Row className="mt-4">
            <Col xs={12} md={12}>
              <InputField
                isRequired
                isValidated={setValidated}
                handleChange={(e: string) => setPostCode(e)}
                label={translate('postcode')}
                type={env.Country === 'NL' ? 'postcode' : 'all'}
                defaultValue={postCode}
                handleFocus={() => handleAddress()}
                isSubmitting={isSubmitting}
              />
            </Col>
          </Row>
          <div className="d-flex m-0 mt-3 align-items-end">
            <div className='mr-2'>
              <InputField
                isRequired
                isValidated={setValidated}
                handleChange={(e: string) => setHouse(e)}
                label={translate('house_number')}
                type="number"
                defaultValue={house}
                handleFocus={() => handleAddress()}
                isSubmitting={isSubmitting}
              />
            </div>
            <div className='ml-2'>
              <Form.Group>
                <FloatingLabel
                  label={translate('addition')}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    value={toevoeg}
                    placeholder={translate('addition')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setToevoeg(e.target.value)
                    }
                  />
                </FloatingLabel>
              </Form.Group>
            </div>
          </div>
          <div className="mobile_details text-end"
           style={{marginTop: '-15px'}}
          >
            {translate('optional')}
          </div>
          {citizen && (
            <div>
              <Form.Group controlId="validationCustom01">
                <FloatingLabel
                  controlId="floatingInput"
                  label={translate('residence')}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder={translate('residence')}
                    value={district}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDistrict(e.target.value)
                    }
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="validationCustom04">
                <>
                  <span className="mb-3 select-text">{translate('cob')}</span>
                  <Form.Select
                    className="full_select"
                    value={country}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setCountry(e.target.value)
                    }
                  >
                    {countryObjects.map((product, index) => (
                      <option value={product.code} key={index}>
                        {product.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              </Form.Group>
              {env.Country !== 'NL' && (
                <Form.Group controlId="validationCustom05">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    checked={checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setChecked(e.target.checked)
                    }
                    label={translate('married')}
                  />
                </Form.Group>
              )}
            </div>
          )}
          {(address && !citizen) && (
              <div className="address-checkbox">
                <p className="m-0">{translate('right_address')}</p>
                <Form.Group controlId="validationCustom05"
                  className='mb-3 mt-2 pt-1 ml-4 address_details'
                >
                  <Form.Check
                    type="checkbox"
                    className='mt-1'
                    id="default-checkbox"
                    checked={isCorrect}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsCorrect(e.target.checked);
                      setValidated(e.target.checked);
                    }}
                    label={<>
                      <div>{`${address?.street_name} 
                    ${house}${toevoeg ? toevoeg : ''}`}</div>
                      <div>{`${address?.neighborhood_code}
                       ${address?.letter_combination} 
                        ${address?.city}`}</div>
                    </>}
                  />
                </Form.Group>
              </div>
          )}
          <div className="mt-4 pb-4">
          {(!citizen && !isCorrect) && (
              <Form.Group controlId="validationCustom05" className='mb-3 ml-4
              warning-checkbox'
              >
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  checked={citizen}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCitizen(e.target.checked);
                  }}
                    label={checked ? translate('live_in_netherlands') :
                      translate('not_live_in_netherlands')
                    }
                />
              </Form.Group>
                )}
            <div>
              <button
                className={`btn ${!validated ?
                  'btn-disabled' : 'btn-success'}
                px-5 mobile_button`}
                onClick={handleSubmit}
              >
                {translate('next_step')}
              </button>
            </div>
            <div className="more-info">
                <svg
                  width="26"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.142578C3.5625 0.142578 0 3.73633 0
                8.14258C0 12.5801 3.5625 16.1426 8 16.1426C12.4062
                  16.1426 16 12.5801 16 8.14258C16 3.73633 12.4062
                  0.142578 8 0.142578ZM8 14.6426C4.40625 14.6426 1.5
                    11.7363 1.5 8.14258C1.5 4.58008 4.40625 1.64258 8
                    1.64258C11.5625 1.64258 14.5 4.58008 14.5
                      8.14258C14.5 11.7363 11.5625 14.6426 8
                      14.6426ZM9.25 10.6426H8.75V7.89258C8.75
                        7.48633 8.40625 7.14258 8 7.14258H7C6.5625
                        7.14258 6.25 7.48633 6.25 7.89258C6.25 8.33008
                          6.5625 8.64258 7 8.64258H7.25V10.6426H6.75C6.3125
                          10.6426 6 10.9863 6 11.3926C6 11.8301 6.3125
                            12.1426 6.75 12.1426H9.25C9.65625 12.1426
                            10 11.8301 10 11.3926C10 10.9863 9.65625
                              10.6426 9.25 10.6426ZM8 6.14258C8.53125
                              6.14258 9 5.70508 9 5.14258C9 4.61133
                                8.53125 4.14258 8 4.14258C7.4375 4.14258
                                7 4.61133 7 5.14258C7 5.70508 7.4375
                                  6.14258 8 6.14258Z"
                    fill="#002172"
                  />
                </svg>
                <span onClick={() => setViewInfo(true)}>
                  {translate('why_we_ask')}
                </span>
              </div>
              {viewInfo && (
              <div className="more-details mt-3">
                <div className="row m-0 justify-content-between">
                  <div className="col-11 text-justify">
                    {translate('why_we_ask_bkr')}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-box-arrow-up-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.636 3.5a.5.5 0 0
                 0-.5-.5H1.5A1.5 1.5
                  0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0
                  1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0
                   1-.5.5h-10a.5.5 0 0
                    1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M16 .5a.5.5 0 0
                 0-.5-.5h-5a.5.5 0 0 0
                  0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15
                   1.707V5.5a.5.5 0 0 0
                  1 0v-5z"
                      />
                    </svg>{' '}
                  </div>
                  <div onClick={() => setViewInfo(false)} className="col-1">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.46875 5.46875C5.75 5.1875 6.21875 5.1875 6.5
                  5.46875L7.96875 6.96875L9.46875 5.46875C9.75 5.1875
                    10.2188 5.1875 10.5 5.46875C10.8125 5.78125 10.8125
                    6.25 10.5 6.53125L9.03125 8L10.5 9.46875C10.8125
                      9.78125 10.8125 10.25 10.5 10.5312C10.2188 10.8438
                      9.75 10.8438 9.46875 10.5312L7.96875 9.0625L6.5
                        10.5312C6.21875 10.8438 5.75 10.8438 5.46875
                        10.5312C5.15625 10.25 5.15625 9.78125 5.46875
                          9.46875L6.9375 8L5.46875 6.53125C5.15625 6.25
                          5.15625 5.78125 5.46875 5.46875ZM16 8C16 12.4375
                            12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375
                            3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8
                              1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938
                              4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938
                                14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
                        fill="#002172"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="previous-button pb-4" onClick={() => handleDone(2)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
        </div>
      ) : (
        <div>
          <Form.Group controlId="validationCustom01">
            <FloatingLabel
              controlId="floatingInput"
              label={translate('dob')}
              className="mb-3"
            >
              <input
                required
                type="date"
                className={`${((!isDobValid && isSubmitting) ||
                  (!isDobValid && dob)) ? 'is-invalid' : ''}
form-control`}
                pattern="\d{4}-\d{2}-\d{2}"
                value={dob}
                max={today}
                placeholder={translate('dob')}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateChange(e.target.value)
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDob(e.target.value)
                }
              />
            </FloatingLabel>
          </Form.Group>
          <InputField
            handleChange={(e: string) => setStreet(e)}
            label={translate('street_name')}
            type="text"
            defaultValue={street}
          />
          <Row className="mt-4">
            <Col xs={6} md={6}>
              <InputField
                isRequired
                isValidated={setValidated}
                handleChange={(e: string) => setHouse(e)}
                label={translate('house_number')}
                type="number"
                defaultValue={house}
                handleFocus={() => handleAddress()}
                isSubmitting={isSubmitting}
              />
            </Col>
            <Col xs={6} md={6}>
              <Form.Group controlId="validationCustom03">
                <FloatingLabel
                  controlId="floatingInput"
                  label={translate('addition')}
                  className="mb-3 level-input"
                >
                  <Form.Control
                    type="text"
                    placeholder={translate('addition')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setToevoeg(e.target.value)
                    }
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <div>
          {env.Country === 'NL' && <Form.Group controlId="validationCustom01">
              <FloatingLabel
                label={translate('city')}
                className="mb-3 text-capitalize"
              >
                <Form.Control
                  placeholder={translate('city')}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>}
            <Form.Group controlId="validationCustom01">
              <FloatingLabel
                controlId="floatingInput"
                label={translate(
                  env.Country === 'SXM' ? 'district' : 'neighbourhood',
                )}
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={district}
                  placeholder={translate(
                    env.Country === 'SXM' ? 'district' : 'neighbourhood',
                  )}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDistrict(e.target.value)
                  }
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="validationCustom04">
              <>
                <span className="mb-3 select-text">{translate('cob')}</span>
                <Form.Select
                  className="full_select"
                  value={country}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCountry(e.target.value)
                  }
                >
                  {countryObjects.map((product, index) => (
                    <option value={product.code} key={index}>
                      {product.name}
                    </option>
                  ))}
                </Form.Select>
              </>
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                checked={checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setChecked(e.target.checked)
                }
                label={translate('married')}
              />
            </Form.Group>
          </div>
          <div className="mt-4 pb-4">
            <div>
              <button
                className={`btn ${!validated ?
                  'btn-disabled' : 'btn-success'}
                px-5 mobile_button`}
                onClick={handleSubmit}
              >
                {translate('next_step')}
              </button>
              </div>
          </div>
          <div className="previous-button pb-4" onClick={() => handleDone(2)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
        </div>
      )}
    </Card.Body>
  );
};

export default PersonForm;
