import env from '@/constants/env';
// import {findCountryByEnv} from './helper';

const countries = {
  'Netherlands (the)': 'NLD',
  'Aruba': 'ABW',
  'Bonaire': 'BES',
  'Curaçao': 'CUW',
  'Sint Maarten': 'SXM',
  'Saint Martin (French part)': 'MAF',
  'Afghanistan': 'AFG',
  'Albania': 'ALB',
  'Algeria': 'DZA',
  'American Samoa': 'ASM',
  'Andorra': 'AND',
  'Angola': 'AGO',
  'Anguilla': 'AIA',
  'Antarctica': 'ATA',
  'Antigua and Barbuda': 'ATG',
  'Argentina': 'ARG',
  'Armenia': 'ARM',
  'Australia': 'AUS',
  'Austria': 'AUT',
  'Azerbaijan': 'AZE',
  'Bahamas (the)': 'BHS',
  'Bahrain': 'BHR',
  'Bangladesh': 'BGD',
  'Barbados': 'BRB',
  'Belarus': 'BLR',
  'Belgium': 'BEL',
  'Belize': 'BLZ',
  'Benin': 'BEN',
  'Bermuda': 'BMU',
  'Bhutan': 'BTN',
  'Bolivia (Plurinational State of)': 'BOL',
  'Bosnia and Herzegovina': 'BIH',
  'Botswana': 'BWA',
  'Bouvet Island': 'BVT',
  'Brazil': 'BRA',
  'British Indian Ocean Territory (the)': 'IOT',
  'Brunei Darussalam': 'BRN',
  'Bulgaria': 'BGR',
  'Burkina Faso': 'BFA',
  'Burundi': 'BDI',
  'Cabo Verde': 'CPV',
  'Cambodia': 'KHM',
  'Cameroon': 'CMR',
  'Canada': 'CAN',
  'Cayman Islands (the)': 'CYM',
  'Central African Republic (the)': 'CAF',
  'Chad': 'TCD',
  'Chile': 'CHL',
  'China': 'CHN',
  'Christmas Island': 'CXR',
  'Cocos (Keeling) Islands (the)': 'CCK',
  'Colombia': 'COL',
  'Comoros (the)': 'COM',
  'Congo (the Democratic Republic of the)': 'COD',
  'Congo (the)': 'COG',
  'Cook Islands (the)': 'COK',
  'Costa Rica': 'CRI',
  'Croatia': 'HRV',
  'Cuba': 'CUB',
  'Cyprus': 'CYP',
  'Czechia': 'CZE',
  'Côte d\'Ivoire': 'CIV',
  'Denmark': 'DNK',
  'Djibouti': 'DJI',
  'Dominica': 'DMA',
  'Dominican Republic (the)': 'DOM',
  'Ecuador': 'ECU',
  'Egypt': 'EGY',
  'El Salvador': 'SLV',
  'Equatorial Guinea': 'GNQ',
  'Eritrea': 'ERI',
  'Estonia': 'EST',
  'Eswatini': 'SWZ',
  'Ethiopia': 'ETH',
  'Falkland Islands (the) [Malvinas]': 'FLK',
  'Faroe Islands (the)': 'FRO',
  'Fiji': 'FJI',
  'Finland': 'FIN',
  'France': 'FRA',
  'French Guiana': 'GUF',
  'French Polynesia': 'PYF',
  'French Southern Territories (the)': 'ATF',
  'Gabon': 'GAB',
  'Gambia (the)': 'GMB',
  'Georgia': 'GEO',
  'Germany': 'DEU',
  'Ghana': 'GHA',
  'Gibraltar': 'GIB',
  'Greece': 'GRC',
  'Greenland': 'GRL',
  'Grenada': 'GRD',
  'Guadeloupe': 'GLP',
  'Guam': 'GUM',
  'Guatemala': 'GTM',
  'Guernsey': 'GGY',
  'Guinea': 'GIN',
  'Guinea-Bissau': 'GNB',
  'Guyana': 'GUY',
  'Haiti': 'HTI',
  'Heard Island and McDonald Islands': 'HMD',
  'Holy See (the)': 'VAT',
  'Honduras': 'HND',
  'Hong Kong': 'HKG',
  'Hungary': 'HUN',
  'Iceland': 'ISL',
  'India': 'IND',
  'Indonesia': 'IDN',
  'Iran (Islamic Republic of)': 'IRN',
  'Iraq': 'IRQ',
  'Ireland': 'IRL',
  'Isle of Man': 'IMN',
  'Israel': 'ISR',
  'Italy': 'ITA',
  'Jamaica': 'JAM',
  'Japan': 'JPN',
  'Jersey': 'JEY',
  'Jordan': 'JOR',
  'Kazakhstan': 'KAZ',
  'Kenya': 'KEN',
  'Kiribati': 'KIR',
  'Korea (the Democratic People\'s Republic of)': 'PRK',
  'Korea (the Republic of)': 'KOR',
  'Kuwait': 'KWT',
  'Kyrgyzstan': 'KGZ',
  'Lao People\'s Democratic Republic (the)': 'LAO',
  'Latvia': 'LVA',
  'Lebanon': 'LBN',
  'Lesotho': 'LSO',
  'Liberia': 'LBR',
  'Libya': 'LBY',
  'Liechtenstein': 'LIE',
  'Lithuania': 'LTU',
  'Luxembourg': 'LUX',
  'Macao': 'MAC',
  'Madagascar': 'MDG',
  'Malawi': 'MWI',
  'Malaysia': 'MYS',
  'Maldives': 'MDV',
  'Mali': 'MLI',
  'Malta': 'MLT',
  'Marshall Islands (the)': 'MHL',
  'Martinique': 'MTQ',
  'Mauritania': 'MRT',
  'Mauritius': 'MUS',
  'Mayotte': 'MYT',
  'Mexico': 'MEX',
  'Micronesia (Federated States of)': 'FSM',
  'Moldova (the Republic of)': 'MDA',
  'Monaco': 'MCO',
  'Mongolia': 'MNG',
  'Montenegro': 'MNE',
  'Montserrat': 'MSR',
  'Morocco': 'MAR',
  'Mozambique': 'MOZ',
  'Myanmar': 'MMR',
  'Namibia': 'NAM',
  'Nauru': 'NRU',
  'Nepal': 'NPL',
  'New Caledonia': 'NCL',
  'New Zealand': 'NZL',
  'Nicaragua': 'NIC',
  'Niger (the)': 'NER',
  'Nigeria': 'NGA',
  'Niue': 'NIU',
  'Norfolk Island': 'NFK',
  'North Macedonia': 'MKD',
  'Northern Mariana Islands (the)': 'MNP',
  'Norway': 'NOR',
  'Oman': 'OMN',
  'Pakistan': 'PAK',
  'Palau': 'PLW',
  'Palestine, State of': 'PSE',
  'Panama': 'PAN',
  'Papua New Guinea': 'PNG',
  'Paraguay': 'PRY',
  'Peru': 'PER',
  'Philippines (the)': 'PHL',
  'Pitcairn': 'PCN',
  'Poland': 'POL',
  'Portugal': 'PRT',
  'Puerto Rico': 'PRI',
  'Qatar': 'QAT',
  'Romania': 'ROU',
  'Russian Federation (the)': 'RUS',
  'Rwanda': 'RWA',
  'Réunion': 'RUE',
  'Saint Barthélemy': 'BLM',
  'Saint Helena, Ascension and Tristan da Cunha': 'SHN',
  'Saint Kitts and Nevis': 'KNA',
  'Saint Lucia': 'LCA',
  'Saint Pierre and Miquelon': 'SPM',
  'Saint Vincent and the Grenadines': 'VCT',
  'Samoa': 'WSM',
  'San Marino': 'SMR',
  'Sao Tome and Principe': 'STP',
  'Saudi Arabia': 'SAU',
  'Senegal': 'SEN',
  'Serbia': 'SRB',
  'Seychelles': 'SYC',
  'Sierra Leone': 'SLE',
  'Singapore': 'SGP',
  'Slovakia': 'SVK',
  'Slovenia': 'SVN',
  'Solomon Islands': 'SLB',
  'Somalia': 'SOM',
  'South Africa': 'ZAF',
  'South Georgia and the South Sandwich Islands': 'SGS',
  'South Sudan': 'SSD',
  'Spain': 'ESP',
  'Sri Lanka': 'LKA',
  'Sudan (the)': 'SDN',
  'Suriname': 'SUR',
  'Svalbard and Jan Mayen': 'SJM',
  'Sweden': 'SWE',
  'Switzerland': 'CHE',
  'Syrian Arab Republic (the)': 'SYR',
  'Taiwan (Province of China)': 'TWN',
  'Tajikistan': 'TJK',
  'Tanzania, the United Republic of': 'TZA',
  'Thailand': 'THA',
  'Timor-Leste': 'TLS',
  'Togo': 'TGO',
  'Tokelau': 'TKL',
  'Tonga': 'TON',
  'Trinidad and Tobago': 'TTO',
  'Tunisia': 'TUN',
  'Turkey': 'TUR',
  'Turkmenistan': 'TKM',
  'Turks and Caicos Islands (the)': 'TCA',
  'Tuvalu': 'TUV',
  'Uganda': 'UGA',
  'Ukraine': 'UKR',
  'United Arab Emirates (the)': 'ARE',
  'United Kingdom of Great Britain and Northern Ireland (the)': 'GBR',
  'United States Minor Outlying Islands (the)': 'UMI',
  'United States of America (the)': 'USA',
  'Uruguay': 'URY',
  'Uzbekistan': 'UZB',
  'Vanuatu': 'VUT',
  'Venezuela (Bolivarian Republic of)': 'VEN',
  'Viet Nam': 'VNM',
  'Virgin Islands (British)': 'VGB',
  'Virgin Islands (U.S.)': 'VIR',
  'Wallis and Futuna': 'WLF',
  'Western Sahara*': 'ESH',
  'Yemen': 'YEM',
  'Zambia': 'ZMB',
  'Zimbabwe': 'ZWE',
  'Åland Islands': 'ALA',
  'Yugoslavia': 'YUG',
  'Kosovo': 'KOS',
};

const countriesDutch = {
  'Nederland': 'NLD',
  'Aruba': 'ABW',
  'Bonaire': 'BES',
  'Curaçao': 'CUW',
  'Sint Maarten': 'SXM',
  'Saint Martin': 'MAF',
  'Afghanistan': 'AFG',
  'Albanië': 'ALB',
  'Algerije': 'DZA',
  'Amerikaans-Samoa': 'ASM',
  'Andorra': 'AND',
  'Angola': 'AGO',
  'Anguilla': 'AIA',
  'Antarctica': 'ATA',
  'Antigua en Barbuda': 'ATG',
  'Argentinië': 'ARG',
  'Armenië': 'ARM',
  'Australië': 'AUS',
  'Azerbeidzjan': 'AZE',
  'Bahama\'s': 'BHS',
  'Bahrein': 'BHR',
  'Bangladesh': 'BGD',
  'Barbados': 'BRB',
  'Belarus': 'BLR',
  'België': 'BEL',
  'Belize': 'BLZ',
  'Benin': 'BEN',
  'Bermuda': 'BMU',
  'Bhutan': 'BTN',
  'Bolivia': 'BOL',
  'Bosnië en Herzegovina': 'BIH',
  'Botswana': 'BWA',
  'Bouveteiland': 'BVT',
  'Brazilië': 'BRA',
  'Brits Indische Oceaanterritorium': 'IOT',
  'Britse Maagdeneilanden': 'VGB',
  'Brunei': 'BRN',
  'Bulgarije': 'BGR',
  'Burkina Faso': 'BFA',
  'Burundi': 'BDI',
  'Cambodja': 'KHM',
  'Canada': 'CAN',
  'Centraal-Afrikaanse Republiek': 'CAF',
  'Chili': 'CHL',
  'China': 'CHN',
  'Christmaseiland': 'CXR',
  'Cocoseilanden': 'CCK',
  'Colombia': 'COL',
  'Comoren': 'COM',
  'Congo-Brazzaville': 'COG',
  'Congo-Kinshasa': 'COD',
  'Cookeilanden': 'COK',
  'Costa Rica': 'CRI',
  'Cuba': 'CUB',
  'Cyprus': 'CYP',
  'Denemarken': 'DNK',
  'Djibouti': 'DJI',
  'Dominica': 'DMA',
  'Dominicaanse Republiek': 'DOM',
  'Duitsland': 'DEU',
  'Ecuador': 'ECU',
  'Egypte': 'EGY',
  'El Salvador': 'SLV',
  'Equatoriaal-Guinea': 'GNQ',
  'Eritrea': 'ERI',
  'Estland': 'EST',
  'Eswatini': 'SWZ',
  'Ethiopië': 'ETH',
  'Faeröer': 'FRO',
  'Falklandeilanden': 'FLK',
  'Fiji': 'FJI',
  'Filipijnen': 'PHL',
  'Finland': 'FIN',
  'Frankrijk': 'FRA',
  'Frans-Guyana': 'GUF',
  'Frans-Polynesië': 'PYF',
  'Franse Zuidelijke en Antarctische Gebieden': 'ATF',
  'Gabon': 'GAB',
  'Gambia': 'GMB',
  'Georgië': 'GEO',
  'Ghana': 'GHA',
  'Gibraltar': 'GIB',
  'Grenada': 'GRD',
  'Griekenland': 'GRC',
  'Groenland': 'GRL',
  'Guadeloupe': 'GLP',
  'Guam': 'GUM',
  'Guatemala': 'GTM',
  'Guernsey': 'GGY',
  'Guinee': 'GIN',
  'Guinee-Bissau': 'GNB',
  'Guyana': 'GUY',
  'Haïti': 'HTI',
  'Heard- en McDonaldeilanden': 'HMD',
  'Honduras': 'HND',
  'Hongarije': 'HUN',
  'Hongkong SAR van China': 'HKG',
  'Ierland': 'IRL',
  'IJsland': 'ISL',
  'India': 'IND',
  'Indonesië': 'IDN',
  'Irak': 'IRQ',
  'Iran': 'IRN',
  'Israël': 'ISR',
  'Italië': 'ITA',
  'Ivoorkust': 'CIV',
  'Jamaica': 'JAM',
  'Japan': 'JPN',
  'Jemen': 'YEM',
  'Jersey': 'JEY',
  'Jordanië': 'JOR',
  'Kaapverdië': 'CPV',
  'Kameroen': 'CMR',
  'Kazachstan': 'KAZ',
  'Kenia': 'KEN',
  'Kirgizië': 'KGZ',
  'Kiribati': 'KIR',
  'Koeweit': 'KWT',
  'Kosovo': 'XKX',
  'Kroatië': 'HRV',
  'Laos': 'LAO',
  'Lesotho': 'LSO',
  'Letland': 'LVA',
  'Libanon': 'LBN',
  'Liberia': 'LBR',
  'Libië': 'LBY',
  'Liechtenstein': 'LIE',
  'Litouwen': 'LTU',
  'Luxemburg': 'LUX',
  'Macao SAR van China': 'MAC',
  'Madagaskar': 'MDG',
  'Malawi': 'MWI',
  'Maldiven': 'MDV',
  'Maleisië': 'MYS',
  'Mali': 'MLI',
  'Malta': 'MLT',
  'Man': 'IMN',
  'Marokko': 'MAR',
  'Marshalleilanden': 'MHL',
  'Martinique': 'MTQ',
  'Mauritanië': 'MRT',
  'Mauritius': 'MUS',
  'Mayotte': 'MYT',
  'Mexico': 'MEX',
  'Micronesia': 'FSM',
  'Moldavië': 'MDA',
  'Monaco': 'MCO',
  'Mongolië': 'MNG',
  'Montenegro': 'MNE',
  'Montserrat': 'MSR',
  'Mozambique': 'MOZ',
  'Myanmar (Birma)': 'MMR',
  'Namibië': 'NAM',
  'Nauru': 'NRU',
  'Nepal': 'NPL',
  'Nicaragua': 'NIC',
  'Nieuw-Caledonië': 'NCL',
  'Nieuw-Zeeland': 'NZL',
  'Niger': 'NER',
  'Nigeria': 'NGA',
  'Niue': 'NIU',
  'Noord-Korea': 'PRK',
  'Noord-Macedonië': 'MKD',
  'Noorwegen': 'NOR',
  'Norfolk': 'NFK',
  'Oekraïne': 'UKR',
  'Oezbekistan': 'UZB',
  'Oman': 'OMN',
  'Oostenrijk': 'AUT',
  'Pakistan': 'PAK',
  'Palau': 'PLW',
  'Palestijnse gebieden': 'PSE',
  'Panama': 'PAN',
  'Papoea-Nieuw-Guinea': 'PNG',
  'Paraguay': 'PRY',
  'Peru': 'PER',
  'Pitcairneilanden': 'PCN',
  'Polen': 'POL',
  'Portugal': 'PRT',
  'Puerto Rico': 'PRI',
  'Qatar': 'QAT',
  'Réunion': 'REU',
  'Roemenië': 'ROU',
  'Rusland': 'RUS',
  'Rwanda': 'RWA',
  'Saint Kitts en Nevis': 'KNA',
  'Saint Lucia': 'LCA',
  'Saint Vincent en de Grenadines': 'VCT',
  'Saint-Barthélemy': 'BLM',
  'Saint-Pierre en Miquelon': 'SPM',
  'Salomonseilanden': 'SLB',
  'Samoa': 'WSM',
  'San Marino': 'SMR',
  'Sao Tomé en Principe': 'STP',
  'Saoedi-Arabië': 'SAU',
  'Senegal': 'SEN',
  'Servië': 'SRB',
  'Seychellen': 'SYC',
  'Sierra Leone': 'SLE',
  'Singapore': 'SGP',
  'Sint-Helena': 'SHN',
  'Slovenië': 'SVN',
  'Slowakije': 'SVK',
  'Soedan': 'SDN',
  'Somalië': 'SOM',
  'Spanje': 'ESP',
  'Spitsbergen en Jan Mayen': 'SJM',
  'Sri Lanka': 'LKA',
  'Suriname': 'SUR',
  'Svalbard en Jan Mayen': 'SJM',
  'Swaziland': 'SWZ',
  'Syrië': 'SYR',
  'Tadzjikistan': 'TJK',
  'Taiwan': 'TWN',
  'Tanzania': 'TZA',
  'Thailand': 'THA',
  'Timor-Leste': 'TLS',
  'Togo': 'TGO',
  'Tokelau': 'TKL',
  'Tonga': 'TON',
  'Trinidad en Tobago': 'TTO',
  'Tsjaad': 'TCD',
  'Tsjechië': 'CZE',
  'Tunesië': 'TUN',
  'Turkije': 'TUR',
  'Turkmenistan': 'TKM',
  'Turks- en Caicoseilanden': 'TCA',
  'Tuvalu': 'TUV',
  'Uganda': 'UGA',
  'Uruguay': 'URY',
  'Vanuatu': 'VUT',
  'Vaticaanstad': 'VAT',
  'Venezuela': 'VEN',
  'Verenigd Koninkrijk': 'GBR',
  'Verenigde Arabische Emiraten': 'ARE',
  'Verenigde Staten': 'USA',
  'Vietnam': 'VNM',
  'Wallis en Futuna': 'WLF',
  'Westelijke Sahara': 'ESH',
  'Zambia': 'ZMB',
  'Zimbabwe': 'ZWE',
  'Zuid-Afrika': 'ZAF',
  'Zuid-Georgia en de Zuidelijke Sandwicheilanden': 'SGS',
  'Zuid-Korea': 'KOR',
  'Zuid-Soedan': 'SSD',
  'Zweden': 'SWE',
  'Zwitserland': 'CHE',
};

export const countryObjects = Object.entries(env.Country === 'NL' ?
  countriesDutch : countries).map(([name, code]) => ({
  name,
  code,
}));

export const islandLegal = () => {
  let isLegalArr = [];
  switch (env.Country) {
    case 'ABW':
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
        'Sole proprietorship',
      ];
      break;
    case 'SXM':
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
        'Sole proprietorship',
      ];
      break;
    case 'CUW':
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
        'Sole proprietorship',
      ];
      break;
    case 'BES':
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
        'Sole proprietorship',
      ];
      break;
    case 'NL':
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
      ];
      break;
    default:
      isLegalArr = [
        'Public limited liability company',
        'Limited liability company',
        'Aruba exempt company',
        'Foundation',
        'Partnerships',
      ];
      break;
  }
  return isLegalArr;
};
export const industries = () => {
  let industries = [];
  switch (env.Country) {
    case 'ABW':
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
    case 'SXM':
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
    case 'CUW':
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
    case 'BES':
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
    case 'NL':
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
    default:
      industries = [
        {
          name: 'Street trading',
          code: 'Ambulante handel',
        },
        {
          name: 'Construction and real estate',
          code: 'Bouw en vastgoed',
        },
        {
          name: 'Culture, sports and recreation',
          code: 'Cultuur, sport en recreatie',
        },
        {
          name: 'Retail',
          code: 'Detailhandel',
        },
        {
          name: 'Wholesale and intermediate trade',
          code: 'Groot- en tussenhandel',
        },
        {
          name: 'Catering',
          code: 'Horeca',
        },
        {
          name: 'Industry and agriculture',
          code: 'Industrie en landbouw',
        },
        {
          name: 'IT services',
          code: 'Informatie en communicatie',
        },
        {
          name: 'Maintenance and repairs',
          code: 'Onderhoud en reparatie',
        },
        {
          name: 'Other',
          code: 'Overige',
        },
        {
          name: 'Training and education',
          code: 'Training en opleiding',
        },
        {
          name: 'Transport',
          code: 'Vervoer',
        },
        {
          name: 'Web shop',
          code: 'Webwinkel',
        },
        {
          name: 'Business services',
          code: 'Zakelijke dienstverlening',
        },
        {
          name: 'Care and healthcare',
          code: 'Zorg en verzorging',
        },
      ];
      break;
  }
  return industries;
};
