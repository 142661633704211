import * as React from 'react';
import Row from 'react-bootstrap/Row';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import env from '@/constants/env';
import {authHttp} from '@/apis/axiosHttp';
// import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import store, {setLeadData} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import {FloatingLabel} from 'react-bootstrap';
import {phoneNumByEnv} from '@/components/utils/helper';
import {countryObjects} from '@/components/utils/countrieslist';

type Props = {
  handleDone: (number: number) => void;
};

const SpouseForm = ({handleDone}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const leadInfo = state.lead;
  const [selectBtn, setSelectBtn] = React.useState<string>(null);
  const [firstName, setFirstName] = React.useState<string>('');
  const [secondName, setSecondName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>(phoneNumByEnv());
  const [isDobValid, setIsDobValid] = React.useState<boolean>(true);
  const [dob, setDob] = React.useState<string>('');
  const [cob, setCob] = React.useState<string>(env.Country);
  const {translate} = useLocales();
  const today = new Date().toISOString().split('T')[0];
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Birthdate__c: dob,
      Country_of_Birth__c: cob,
    };
    updateLead(payload);
  };


  const updateLead = (payload) => {
    const toastId = toast.loading('loading...');
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      Spouse_application_data__c: JSON.stringify(payload),
      Lead_Scoring__c: 'Hot',
    })
    // apiHttp.create('api/salesforce/', {
    //   'method': 'PATCH',
    //   'path': `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //   'headers': [
    //     {
    //       'key': 'Content-Type',
    //       'value': 'application/json',
    //     },
    //   ],
    //   'body': {
    //     Spouse_application_data__c: JSON.stringify(payload),
    //     Lead_Scoring__c: 'Hot',
    //   },
    // })
      .then(() => {
    handleDone(5);
    store.dispatch(setLeadData({
      ...store.getState().lead,
      Spouse_application_data__c: JSON.stringify(payload),
    }));
      toast.success(translate('submitted_details_success'), {
        id: toastId,
      });
    }).catch(() => {
      toast.error(translate('details_fail_submission'), {
        id: toastId,
      });
    });
  };

  React.useEffect(() => {
    const leading = JSON.parse(leadInfo?.Spouse_application_data__c || '{}');
    if (leading?.FirstName) {
      setFirstName(leading?.FirstName || '');
      setSecondName(leading?.LastName || '');
      setEmail(leading?.Email || '');
      setSelectBtn(leading?.Gender__c || '');
      setPhone(leading?.Phone || '');
      setDob(leading?.Birthdate__c || '');
      setCob(leading?.Country_of_Birth__c || '');
    }
  }, [leadInfo]);

  React.useEffect(() => {
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Medium',
      Birthdate__c: dob,
      Country_of_Birth__c: cob,
    };
    store.dispatch(setLeadData({
      ...store.getState().lead,
      Spouse_application_data__c: JSON.stringify(payload),
    }));
    if (firstName && secondName && selectBtn && email && phone)
      setValidated(true);
    else setValidated(false);
  }, [firstName, secondName, selectBtn, email, phone, dob, cob]);

  const handleDateChange = (value) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
    const minDate = new Date(
      currentDate.getFullYear() - 100,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const maxDate = new Date(
      currentDate.getFullYear() - 16,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    if (inputDate < minDate || inputDate > maxDate) {
      setIsDobValid(false);
      toast.error(translate('age_incorrect_msg'));
    } else {
      setIsDobValid(true);
    }
  };
  return (
    <Card.Body className="mobile_form pt-3">
      <div className="mobile_header">{translate('spouse')}</div>
      <p className="mt-4 mb-0 mobile_details">
        {translate('have_account_log_to')} &nbsp;
        <span className="text-warning"><a href="https://portal.mijnqredits.nl/">{translate('my_qredits')}</a></span>
      </p>
      <p className="mt-1 mobile_details">
        {translate('is_intermediary')} &nbsp;
        <span className="text-warning">{translate('click_here')}</span>
      </p>
      <Form>
        <Row className="mb-3 align-items-center">
          <Form.Group controlId="validationCustom01">
            <ButtonGroup aria-label="Basic example">
              <Button
                variant={
                  selectBtn === 'Female' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Female')}
              >
                {translate('mrs')}
              </Button>
              <Button
                variant={
                  selectBtn === 'Male' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Male')}
              >
                {translate('mr')}
              </Button>
              {/* <Button
                variant={
                  selectBtn === 'Neutral' ?
                    'outline-success' :
                    'outline-primary'
                }
                onClick={() => setSelectBtn('Neutral')}
              >
                {translate('other')}
              </Button> */}
            </ButtonGroup>
          </Form.Group>
        </Row>
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setFirstName(e)}
          label={translate('f_name')}
          type="text"
          defaultValue={firstName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setSecondName(e)}
          label={translate('l_name')}
          type="text"
          defaultValue={secondName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setEmail(e)}
          label={translate('email')}
          type="email"
          defaultValue={email}
          compareEmail={leadInfo.Email}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setPhone(e)}
          label={translate('phone')}
          type="tel"
          defaultValue={phone}
        />
               <>
          <span className="mb-3 select-text">{translate('cob')}</span>
          <Form.Select
            className="full_select"
            value={cob}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setCob(e.target.value)
            }
          >
            {countryObjects.map((product, index) => (
              <option value={product.code} key={index}>
                {product.name}
              </option>
            ))}
          </Form.Select>
        </>
        <Form.Group controlId="validationCustom06">
          <FloatingLabel
            controlId="floatingInput"
            label={translate('dob')}
            className="mb-3"
          >
            <input
              required
              type="date"
              className={`${!isDobValid && dob ?
                'is-invalid' : ''} form-control`}
              pattern="\d{4}-\d{2}-\d{2}"
              max={today}
              value={dob}
              placeholder={translate('dob')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDob(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDateChange(e.target.value)
              }
            />
          </FloatingLabel>
        </Form.Group>
      </Form>
      <div className="mt-4 pb-4">
        <div>
          <button
            type="submit"
            className="btn btn-success
            px-5 mobile_button"
            disabled={!validated}
            onClick={handleSubmit}
          >
            {translate('next_step')}
          </button>
        </div>
      </div>
      <div className="previous-button pb-4" onClick={() => handleDone(3)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
    </Card.Body>
  );
};

export default SpouseForm;
